<template>
  <TheWrapper>
    <form
      novalidate="novalidate"
      class="wrapper__container"
      @submit.prevent="next"
    >
      <h2 class="step__main-title">
        {{ $t('How would you like to return?') }}
      </h2>

      <ItemSelector
        v-if="hasDropOffDelivery && selectedLabellessServicePoint"
        field-id="labeless"
        group="return_location"
        class="location"
        :class="isSelectedServicePointTemporarilyClosed ? 'item-selector__radio--disable' : ''"
        :checked="returnLocationType === 'drop_off_labelless'"
        :visible-checkmark="true"
        value="drop_off_labelless"
        data-test="drop_off_labelless"
        @on-change="selectDropoffOption"
      >
        <DropOffPointAddress v-bind="selectedLabellessServicePoint">
          <template #title>
            {{ $t('At a drop-off point') }}
            <label class="ui-badge ui-badge--primary ui-margin:left-medium">{{ $t('No printer needed') }}</label>
          </template>
          <template #icon>
            <FontAwesomeIcon :icon="$options.faMapMarkerAlt" />
          </template>
        </DropOffPointAddress>
        <p
          v-if="isSelectedServicePointTemporarilyClosed"
          class="ui-margin:bottom-none"
        >
          <FontAwesomeIcon
            :icon="$options.faExclamationTriangle"
            class="item-selector__info-icon"
          />
          <span>{{ $t('Temporarily unavailable') }}</span>
        </p>
        <p
          v-if="enableReturnFee"
          class="location__cost"
        >
          {{ $t('Return costs: {cost}', { cost: returnFee }) }}
        </p>
        <template #footer>
          <UiButton
            mode="secondary"
            class="location__button-wrap"
          >
            <router-link
              class="location__button small-button__btn"
              :to="{ name: 'drop-off-points', query: { isLabelless: true } }"
            >
              <FontAwesomeIcon
                :icon="$options.faCaretRight"
                class="location__button-addon"
              />
              <span>{{ $t('Change Drop Off Point') }}</span>
            </router-link>
          </UiButton>
        </template>
      </ItemSelector>

      <ItemSelector
        v-if="hasDropOffDelivery && selectedServicePoint"
        field-id="dropoff"
        group="return_location"
        class="location"
        :class="isSelectedServicePointTemporarilyClosed ? 'item-selector__radio--disable' : ''"
        :checked="returnLocationType === 'dropoff'"
        :disabled="isSelectedServicePointTemporarilyClosed"
        :visible-checkmark="true"
        value="dropoff"
        data-test="dropoff"
        @on-change="selectDropoffOption"
      >
        <DropOffPointAddress v-bind="selectedServicePoint">
          <template #title>
            {{ $t('At a drop-off point') }}
            <span v-if="selectedLabellessServicePoint">
              {{ $t(' - Print at home') }}
            </span>
          </template>
          <template #icon>
            <FontAwesomeIcon :icon="$options.faMapMarkerAlt" />
          </template>
        </DropOffPointAddress>
        <p
          v-if="isSelectedServicePointTemporarilyClosed"
          class="ui-margin:bottom-none"
        >
          <FontAwesomeIcon
            :icon="$options.faExclamationTriangle"
            class="item-selector__info-icon"
          />
          <span>{{ $t('Temporarily unavailable') }}</span>
        </p>

        <p
          v-if="enableReturnFee"
          class="location__cost"
        >
          {{ $t('Return costs: {cost}', { cost: returnFee }) }}
        </p>
        <template #footer>
          <UiButton
            mode="secondary"
            class="location__button-wrap"
          >
            <router-link
              class="location__button small-button__btn"
              :to="{ name: 'drop-off-points' }"
            >
              <FontAwesomeIcon
                :icon="$options.faCaretRight"
                class="location__button-addon"
              />
              <span>{{ $t('Change Drop Off Point') }}</span>
            </router-link>
          </UiButton>
        </template>
      </ItemSelector>

      <!--  Carrier select implementation -->
      <template v-if="hasDropOffDelivery && !selectedServicePoint && availableCarriers.length > 0">
        <ItemSelector
          v-for="availableCarrier in availableCarriers"
          :key="availableCarrier.code"
          :field-id="`allowed-carrier-${availableCarrier.code}`"
          group="return_location"
          class="location"
          :checked="returnLocationType === 'dropoff' && selectedCarrierCode === availableCarrier.code"
          :visible-checkmark="true"
          :value="availableCarrier.code"
          data-test="allowed-carrier"
          @on-change="selectCarrier(availableCarrier)"
        >
          <CarrierSelector>
            <template
              #title
            >
              {{ availableCarrier.name }} - {{ $t('At a drop-off point') }}
              <span v-if="isShippinProductERSCarrier(availableCarrier)">{{
                $t('of {nationalCarrierName}', { nationalCarrierName: nationalCarrier.name })
              }}</span>
            </template>

            <p
              v-if="enableReturnFee"
              class="location__cost"
            >
              {{ $t('Return costs: {cost}', { cost: returnFee }) }}
            </p>
          </CarrierSelector>
          <p
            v-if="isShippinProductERSCarrier(availableCarrier)"
            class="location__ers-message"
          >
            <FontAwesomeIcon
              :icon="$options.faInfoCircle"
              class="location__ers-icon"
            />
            <i18n-t
              keypath="There is no {originalCarrier} drop-off point in your country. Drop off the package at {newCarrier}."
              data-test="ers-hint"
              class="location__ers-hint"
              tag="span"
              scope="global"
            >
              <template #originalCarrier>
                {{ availableCarrier.name }}
              </template>
              <template #newCarrier>
                <b>{{ nationalCarrier.name }}</b>
              </template>
            </i18n-t>
          </p>
          <template
            v-if="getDropoffButtonUrl(availableCarrier)"
            #footer
          >
            <UiButton
              mode="secondary"
              class="location__button-wrap"
            >
              <a
                class="location__button small-button__btn"
                :href="getDropoffButtonUrl(availableCarrier)"
                target="_blank"
                data-test="allowed-carrier-button-url"
              >
                <FontAwesomeIcon
                  :icon="$options.faCaretRight"
                  class="location__button-addon"
                />
                <span>{{ $t('Find the drop-off point') }}</span>
              </a>
            </UiButton>
          </template>
        </ItemSelector>
      </template>

      <template v-if="hasPickupReturn && availableCarriers.length > 0">
        <ItemSelector
          v-for="pickupMethodCarrier in pickupMethodCarriers"
          :key="`pickup-${pickupMethodCarrier.code}`"
          :field-id="`pickup-carrier-${pickupMethodCarrier.code}`"
          group="return_location"
          class="location"
          :checked="returnLocationType === 'pickup' && selectedCarrierCode === pickupMethodCarrier.code"
          :visible-checkmark="true"
          :value="pickupMethodCarrier.code"
          data-test="pickup-carrier"
          @on-change="selectCarrier(pickupMethodCarrier)"
        >
          <CarrierSelector :carrier="pickupMethodCarrier">
            <template #title>{{ pickupMethodCarrier.name }} - {{ $t('Arrange a pick-up') }} </template>
            <p
              class="pickup__return-carrier-message"
              data-test="pickup-return-selector"
            >
              <span>{{ $t('Your package will be picked up by a carrier service') }}</span>
            </p>
            <p>
              <span>
                <FontAwesomeIcon
                  :icon="$options.faClock"
                  class="item-selector__info-icon"
                />
                {{
                  $t('Next business day, {date} between {startTime} and {endTime}', {
                    ...(({ date, startTime, endTime }) => ({ date, startTime, endTime }))(
                      getReturnPickupDate(pickupMethodCarrier)
                    ),
                  })
                }}
              </span>
            </p>
            <p
              v-if="enableReturnFee"
              class="location__cost"
            >
              {{ $t('Return costs: {cost}', { cost: returnFee }) }}
            </p>
          </CarrierSelector>
        </ItemSelector>
      </template>

      <ItemSelector
        v-if="storeAddresses.length > 0 && hasStoreDelivery"
        field-id="store"
        group="return_location"
        class="location"
        :checked="returnLocationType === 'store'"
        :visible-checkmark="true"
        value="store"
        data-test="selected-store"
        @on-change="selectStoreOption"
      >
        <p class="location__title">
          {{ $t('Return in store') }}
        </p>
        <AddressFormatted v-bind="selectedAddressFormatted">
          <template #icon>
            <FontAwesomeIcon :icon="$options.faMapMarkerAlt" />
          </template>
        </AddressFormatted>

        <p
          v-if="enableReturnFee"
          class="location__cost"
        >
          {{ $t('Return costs: {cost}', { cost: $t('Free') }) }}
        </p>
        <template #footer>
          <UiButton
            mode="secondary"
            class="location__button-wrap"
          >
            <router-link
              :to="{ name: 'store' }"
              class="location__button small-button__btn"
            >
              <FontAwesomeIcon
                :icon="$options.faCaretRight"
                class="location__button-addon"
              />
              <span>{{ $t('Change store') }}</span>
            </router-link>
          </UiButton>
        </template>
      </ItemSelector>
      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          class="main-button__btn"
          :disabled="disableNext"
          data-test="main-button"
          :style="brandStyle"
        >
          <span>{{ $t('Go to summary') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>

<script>

/* eslint vue/singleline-html-element-content-newline: "off" */
import {
  APP_SET_LOCATION_TYPE,
  APP_SET_STORE_LOCATION_ID,
  APP_SET_SELECTED_CARRIER_CODE,
  APP_SET_PICKUP_DATE,
} from '@/store/mutation.types'
import { brandColourMixin } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInfoCircle, faClock, faCaretRight, faChevronRight, faMapMarkerAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { mapState, mapGetters } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import ItemSelector from '@/components/common/ItemSelector'
import CarrierSelector from '@/components/common/CarrierSelector'
import AddressFormatted from '@/components/common/AddressFormatted'
import DropOffPointAddress from '@/components/common/DropOffPointAddress'
import TheWrapper from '@/components/layout/TheWrapper'

export default {
  name: 'ReturnsLocation',
  faInfoCircle,
  faCaretRight,
  faChevronRight,
  faMapMarkerAlt,
  faExclamationTriangle,
  faClock,
  components: {
    AddressFormatted,
    CarrierSelector,
    DropOffPointAddress,
    FontAwesomeIcon,
    ItemSelector,
    TheWrapper,
    UiButton,
  },
  mixins: [brandColourMixin],
  data() {
    return {
      limitReturnPickupEpoch: new Date().setHours(17, 0, 0, 0),
    }
  },
  computed: {
    ...mapGetters(['dropOffPointAddress', 'isInternationalReturn']),
    ...mapState({
      shippingProducts: (state) => state.shippingProducts,
      // returns a list of return carriers (as shipping products),
      // a return carrier is added to the list per shipping product method functionalities i.e.('labeless'|'ers') available
      // or default shipping product if no such a functionalities are available.
      availableCarriers: ({ shippingProducts, allowedCarriers }) => {
        const availableCarriers = []

        if (!allowedCarriers || !shippingProducts) {
          return false
        }

        for (const carrierKey in allowedCarriers) {
          if (!allowedCarriers[carrierKey]) {
            continue
          }

          const enrichedCarrier = allowedCarriers[carrierKey]

          enrichedCarrier.shippingProducts = shippingProducts.filter(
            (product) => product.carrier === enrichedCarrier.code
          )
          if (enrichedCarrier.shippingProducts.length === 0) {
            continue
          }

          enrichedCarrier.hasErsFunctionality = !!enrichedCarrier.shippingProducts.find((product) =>
            product.methods.find((method) => method.functionalities?.ers === true)
          )

          enrichedCarrier.hasLabellessFunctionality = !!enrichedCarrier.shippingProducts.find((product) =>
            product.methods.find((method) => method.functionalities?.labelless === true)
          )

          enrichedCarrier.hasPickupFunctionality = !!enrichedCarrier.shippingProducts.find(
            (product) =>
              product.methods.find((method) => method.functionalities?.first_mile === 'pickup') &&
              enrichedCarrier.code === 'gls_nl'
          )

          availableCarriers.push(enrichedCarrier)
        }

        return availableCarriers
      },
      // to test if the field is labelless, comment this isLabelless state, and add it to the data as true
      carrierDropOffFinders: (state) => state.carrierDropOffFinders,
      allowedCarriers: (state) => state.allowedCarriers,
      deliveryOptions: (state) => state.deliveryOptions,
      enableReturnFee: (state) => state.settings.enable_refunds,
      returnFee(state) {
        if (!state.settings.enable_refunds) return ''
        let fee = parseFloat(state.returnFee)
        const applicableActionReturnFee = state.applicableActions?.changeReturnFee
        if (applicableActionReturnFee) {
          fee = parseFloat(applicableActionReturnFee)
        }
        if (fee === 0) return this.$t('Free')
        else return this.$n(fee, 'currency')
      },
      returnLocationType: (state) => state.returnLocationType,
      selectedServicePoint: (state) => state.selectedServicePoint,
      selectedLabellessServicePoint: (state) => state.selectedLabellessServicePoint,
      selectedStore: (state) => state.selectedStore,
      storeAddresses: (state) => state.storeAddresses,
      selectedCarrierCode: (state) => state.selectedCarrierCode,
      nationalCarrier: (state) => state.nationalCarrier,
    }),
    disableNext() {
      return (
        !this.returnLocationType ||
        (this.returnLocationType === 'dropoff' && !this.dropOffPointAddress && !this.selectedCarrierCode)
      )
    },
    hasDropOffDelivery() {
      return this.deliveryOptions.some((delivery) => delivery === 'drop_off_point')
    },
    hasPickupReturn() {
      return this.deliveryOptions.some((option) => option === 'pickup')
    },
    hasStoreDelivery() {
      return this.deliveryOptions.some((delivery) => delivery === 'in_store')
    },
    isSelectedServicePointTemporarilyClosed() {
      return !this.selectedServicePoint?.open_upcoming_week
    },
    selectedAddressFormatted() {
      const address = this.storeAddresses.find((item) => item.id === this.selectedStore)
      if (address !== undefined) return address
      const defaultAddress = this.storeAddresses.find((item) => item.is_default === true)
      if (defaultAddress !== undefined) return defaultAddress
      return this.storeAddresses[0]
    },
    pickupMethodCarriers() {
      return this.availableCarriers.filter((carrier) => carrier.hasPickupFunctionality)
    },
  },
  methods: {
    next() {
      this.$router.push({ name: 'summary' }).catch(handleRouteRejection)
    },
    selectDropoffOption(_select, value) {
      if (!value) {
        return
      }
      this.$store.commit(APP_SET_LOCATION_TYPE, value)
      this.$store.commit(APP_SET_SELECTED_CARRIER_CODE, undefined)
    },
    selectStoreOption(select, value) {
      if (!value) {
        return
      }
      this.selectDropoffOption(select, value)

      this.$store.commit(APP_SET_STORE_LOCATION_ID, this.selectedAddressFormatted.id)
    },
    selectCarrier(enrichedCarrier) {
      if (!enrichedCarrier) {
        return
      }
      const { code, hasPickupFunctionality } = enrichedCarrier

      this.$store.commit(APP_SET_LOCATION_TYPE, hasPickupFunctionality ? 'pickup' : 'dropoff')

      this.$store.commit(APP_SET_SELECTED_CARRIER_CODE, code)

      if (hasPickupFunctionality) {
        this.$store.commit(APP_SET_PICKUP_DATE, this.getReturnPickupDate(enrichedCarrier))
      }
    },
    isShippinProductERSCarrier(enrichedCarrier) {
      return this.isInternationalReturn && !!enrichedCarrier.hasErsFunctionality
    },
    getDropoffButtonUrl(enrichedCarrier) {
      return this.isShippinProductERSCarrier(enrichedCarrier)
        ? this.nationalCarrier?.drop_off_finder
        : this.carrierDropOffFinders[enrichedCarrier.code]
    },
    isBefore5PM() {
      return new Date().getTime() < this.limitReturnPickupEpoch
    },
    getReturnPickupMethod(enrichedCarrier) {
      return enrichedCarrier.shippingProducts.reduce((acc, product) => {
        const pickupMethod = product.methods.find((method) => method.functionalities?.first_mile === 'pickup')
        if (pickupMethod) {
          acc = pickupMethod
        }
        return acc
      }, {})
    },
    getReturnPickupDate(carrier) {
      const { locale } = this.$i18n
      const returnPickupMethod = this.getReturnPickupMethod(carrier)
      const { next_business_day, start_time: startTime, end_time: endTime } = returnPickupMethod?.pickup_date
      const date = new Date(next_business_day)
      const day = date.getDate()
      const month = date.toLocaleString(locale, { month: 'long' })
      return { date: `${day} ${month}`, startTime, endTime, next_business_day }
    },
  },
}
</script>

<style lang="scss" scoped>
.location__title {
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 0;
}
.location__description {
  margin: 0;
  color: #4d4f5c;
}
.location__cost {
  font-weight: 700;
  margin-top: 1.3rem;
}
.location__button-wrap {
  padding-left: calc(4.5rem - 13px);
  margin-top: 8px;
}
.location__button-addon {
  margin-right: 0.625rem;
}
.location__ers-message {
  font-size: 12px;
}
.pickup__return-carrier-message {
  font-weight: normal;
}
.location__ers-hint {
  line-height: 15px;
}
.location__ers-icon {
  margin-right: 6px;
}
</style>
