<template>
  <div :class="carrierSelectorClasses">
    <div class="carrier-selector__header">
      <div
        v-if="$slots.title"
        class="carrier-selector__title"
      >
        <slot name="title" />
      </div>
      <slot />
    </div>
    <div class="carrier-selector__misc">
      <div
        v-if="carrier"
        class="carrier-selector__icon"
      >
        <img
          :alt="carrier.name"
          :src="carrier.logo.icon"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarrierSelector',
  props: {
    carrier: { type: Object, required: false, default: () => undefined },
  },
  computed: {
    carrierSelectorClasses() {
      return {
        'carrier-selector': true,
        'carrier-selector--with-title': this.$slots.title,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.carrier-selector {
  display: flex;
}

.carrier-selector__header {
  font-weight: 700;
  flex-grow: 1;
}

.carrier-selector__misc {
  align-items: center;
  justify-content: center;
  flex-grow: 0;

  .carrier-selector--with-title & {
    grid-row-start: 3;
  }

  @media (max-width: 30rem) {
    display: none;
  }
}

.carrier-selector__icon {
  width: 3rem;
  text-align: center;

  @media (min-width: 37.5rem) {
    margin-right: 0;
    order: 2;
  }
}

.carrier-selector__icon img {
  max-height: 34px;
  max-width: 34px;
}
</style>
